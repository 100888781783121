.ButtonDefaultWrapper,
.ButtonPrimaryWrapper,
.ButtonCancelWrapper {
  .ant-btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 4px;
    font-family: sans-serif;

    span {
      font-weight: 600;
      font-size: 14px;
      color: #1D1F21;
    }

    &:disabled {
      opacity: 0.48;
      cursor: not-allowed;
    }
  }
}

.ButtonDefaultWrapper {
  .ant-btn {
    background: #fff;
    border-color: #AAB4B5;

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border-color: #666;
      }
    }

    &.ButtonDefaultDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #fff;
        border-color: #AAB4B5;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}

.ButtonPrimaryWrapper {
  .ant-btn {
    background: #F8E800;
    border-color: #F8E800;

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border-color: #F5D400;
        background: #F5D400;
      }
    }

    &.ButtonPrimaryDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #F8E800;
        border-color: #F8E800;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}


.ButtonCancelWrapper {
  .ant-btn {
    background: #fff;
    border-color: #FF3B30;

    span {
      color: #FF3B30;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        border-color: #FF3B30;
        background: #fff;
      }
    }

    &.ButtonPrimaryDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #fff;
        border-color: #FF3B30;
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
}


.ButtonFadeWrapper, .ButtonFadeRedWrapper {
  .ant-btn {
    background: #F2F5F7;
    border: 1px solid #AAB4B5;
    padding: 12px 16px;
    height: 48px;
    border-radius: 4px;

    span {
      color: #000;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-top: 4px;
      line-height: 16px;
    }

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
        border: 1px solid #AAB4B5;
        background: #F2F5F7;
      }
    }

    &.ButtonFadeDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        border: 1px solid #AAB4B5;
        background: #F2F5F7;
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}


.ButtonFadeRedWrapper {
  .ant-btn {
    background: #FFF5F5 !important;
    border: 1px solid #FF6B6B !important;

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
        background: #FFF5F5 !important;
        border: 1px solid #FF6B6B !important;
      }
    }

    &.ButtonFadeRedDisabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active {
        background: #FFF5F5 !important;
        border: 1px solid #FF6B6B !important;
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}