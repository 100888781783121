.CreateModalDateCalendarComponent .CalendarComponentWrapper
.ant-picker-calendar .ant-picker-panel {
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.CalendarComponentHeader {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  padding-top: 24px;
}

.CalendarComponentHeader .CalendarComponentHeaderDate {
  width: 190px;
  text-align: center;
}

.CalendarComponentHeaderArrow:hover {
  cursor: pointer;
  opacity: 0.7;
}

.CalendarComponentHeaderDate {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #1D1F21;
  padding-right: 37px;
  padding-left: 37px;
}

.CalendarGraduationPopup {
  margin: -6px -10px;
}

.CalendarGraduationWrapper {
  position: absolute;
  bottom: 12px;
  left: 12px;
  display: flex;
  flex-direction: row;
  z-index: 9999 !important;
}

.CalendarGraduationPopupItem {
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  width: 258px;
}

.GraduationIconWrapper {
  padding: 4px 4px 0 4px;
  height: 24px;
  width: 26px;
  background: rgba(248, 232, 0, 1);
  box-shadow: 0px 2.63039px 3.94558px rgba(62, 73, 84, 0.04);
  border-radius: 3px;
  z-index: 9999 !important;
}

.DisabledGraduationIconWrapper {
  padding: 4px 4px 0 4px;
  height: 24px;
  width: 26px;
  box-shadow: 0px 2.63039px 3.94558px rgba(62, 73, 84, 0.04);
  border-radius: 3px;
  background: #AAB4B5;
}

.GraduationIconWrapper:hover {
  cursor: pointer;
  background: rgba(248, 232, 0, 0.8);
}


.GraduationPopupItemActive:hover {
  cursor: pointer;
  background-color: #F2F5F7;
  border-radius: 6px;
}

.CourseHoverInfo {
  position: absolute;
  background: #FEFACC;
  box-shadow: 0px 2.63039px 3.94558px rgba(62, 73, 84, 0.04);
  border-radius: 3px;
  left: 26px;
  height: 24px;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
}

.CoursePopupItemName {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #1D1F21;
}

.CoursePopupItemInfo {
  padding-left: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #545454;
}

.CoursePopupItemPlace,
.CoursePopupItemWithoutFreePlace {
  font-weight: 700;
}

.CoursePopupItemWithoutFreePlace {
  color: red !important;
}

.CourseHoverInfoDateStart {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
  padding-left: 8px;
}

.CourseHoverInfoHour {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #1D1F21;
  padding-left: 11px;
}

@media screen and (min-width: 1121px) {
  .CreateModalDateCalendarComponent {
    margin-top: -24px;
  }
}

@media screen and (max-width: 1120px) {
  .CreateModalDateCalendarComponent .CalendarComponentWrapper
  .ant-picker-calendar .CalendarComponentHeader {
    margin: 0 !important;
  }
  .CreateModalDateCalendarComponent .CalendarComponentWrapper
  .ant-picker-calendar .ant-picker-panel {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
  }
}

@media screen and (max-width: 450px) {
  .CreateModalDateCalendarComponent .CalendarComponentWrapper
  .ant-picker-calendar .ant-picker-panel {
    max-height: calc(100vh - 450px) !important;
  }
}
