.SuccessPageWrapper {
  padding: 16px 5%;
}

.SuccessPageEvents {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SuccessPageEventsLeft {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.SuccessPageTitleDescriptions {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
}

.SuccessPageEventsRight {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SuccessPageEventsRightButton {
  padding-right: 16px;
  margin: 5px 0;
}

.SuccessPageEventsRightInput {
  margin: 5px 0;
}

.HistoryTableCourseNumber {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1D1F21;
}

.HistoryTableMoreWrapper {
  width: 32px;
  font-size: 26px;
  color: #808889;
  padding: 0 0 14px 0;
  display: grid;
  place-items: center;
}

.HistoryTableMoreWrapper:hover {
  color: #545454;
  background: #F2F5F7;
  border-radius: 8px;
  cursor: pointer;
}

.HistoryTableCourseDescription {
  padding-top: 5px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
}

.HistoryTableCourseLabel {
  color: #1D1F21;
}

.HistoryTableWrapper .ComponentWrapper
div div div div .ant-table,
.ant-table-container {
  border-radius: 10px 10px 0 0 !important;
}

.ant-table-thead .ant-table-cell {
  &:first-child {
    border-radius: 10px 0 0 0 !important;
  }

  &:last-child {
    border-radius: 0 10px 0 0 !important;
  }
}

.HistoryTableWrapper .ComponentWrapper
div div div div div .ant-table-container
.ant-table-header table .ant-table-thead tr > th {
  font-family: sans-serif;
  background: #fff !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #1d1f21 !important;
  padding: 4px 16px !important;
}

.ant-table-cell-scrollbar {
  box-shadow: none !important;
  width: 25px;
}

.HistoryTableWrapper .ComponentWrapper
div div div div div .ant-table-container  .ant-table-tbody {
  .ant-table-cell {
    color: #545454;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    &.highlight-color {
      color: #1d1f21;
    }
  }

  > tr:not(.ant-table-measure-row) {
    > .ant-table-cell {
      padding: 24px 16px !important;

      &:first-child {
        padding-left: 32px !important;
      }
    }
  }
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  color: #aab4b5 !important;

  &.active {
    color: #545454 !important;
  }
}


.SuccessPageTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #1D1F21;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SuccessPageTitleButton {
  padding-right: 16px;
  margin-top: -6px;
}

.SuccessPageTitleButton:hover {
  cursor: pointer;
}

.SuccessPageInfoWrapper {
  background: #FFFFFF;
  padding: 29px 0;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}

.SuccessPageInfoItem {
  display: flex;
  flex: 1 1 0;
  position: relative;

  &:not(:first-child) {
    &:before {
      content: '';
      position: absolute;
      left: 20px;
      top: 0;
      background: #ECECEC;
      height: 1px;
      right: 20px;
    }
  }
}

.SuccessPageInfoInner {
  display: inline-flex;
  flex-direction: column;
  padding: 0 15px;
}

.SuccessPageInfoText {
  flex-direction: column;
}

.SuccessPageInfoIcon {
  margin-right: 16px;
}

.SuccessPageInfoLabel {
  font-weight: 500;
  font-size: 11px;
  color: #808889;
  text-transform: uppercase;
}

.SuccessPageInfoDuration {
  font-size: 12px;
  color: #545454;
}

.SuccessPageInfoValue {
  font-weight: 600;
  font-size: 16px;
}

.SuccessCourseTableName {
  font-family: sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1D1F21;
}

.SuccessCourseTableItem {
  font-family: sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #808889;
}

.SuccessCourseTableComment {
  font-family: sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #545454;
}

.SuccessCourseTablePercentWrapper {
  display: flex;
  flex-direction: column;
}

.SuccessCourseTablePercentText {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #1D1F21;
}

@media screen and (min-width: 992px) {
  .SuccessPageWrapper {
   padding: 32px 10%;
  }

  .SuccessPageInfoWrapper {
    flex-direction: row;
  }

  .SuccessPageInfoInner {
    margin: 0 auto;
    flex-direction: row;
    align-items: center
  }

  .SuccessPageInfoItem:not(:first-child)::before {
    left: 0;
    top: -9px;
    bottom: -9px;
    width: 1px;
    height: auto;
  }
}

@media screen and (max-width: 992px) {
  .SuccessPageWrapper {
    padding: 20px 5%;
  }

  .SuccessPageInfoItem:not(:first-child) {
    padding-top: 15px;
    margin-top: 15px;
  }
}
