.CourseWrapper {
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  margin: 0 -10px;
  max-height: calc(100vh - 348px);
  overflow-y: auto;
}

.CourseItem {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  box-shadow: -5px 0px 30px #F2F5F7;
  border-radius: 8px;
  width: 100%;
}

.CourseItemInfo {
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  padding-bottom: 33px;
  padding-left: 30px;
}

.CourseItemDays {
  display: flex;
  flex-direction: row;
  margin-top: auto;
}

.CourseItemDaysValue {
  font-weight: bold;
  font-size: 32px;
  text-align: right;
  color: #545454;
}

.CourseItemDaysLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #545454;
  padding-left: 4px;
  padding-bottom: 16px;
  padding-right: 16px;
  margin-top: 19px;
}

.CourseItemActive, .CourseItem:hover {
  background: rgba(248, 232, 0, 0.1);
  border: 1px solid #F8E800;
  cursor: pointer;
}

.CourseItemLabel {
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  color: #1D1F21;
}

.CourseItemDescription {
  text-transform: lowercase;
  font-size: 14px;
  line-height: 26px;
  color: #545454;
  font-family: sans-serif;
}

@media screen and (min-width: 1121px) {
 .CourseItem {
   max-width: 415px;
 }
}
