.SelectWrapper,
.TreeSelectWrapper {
  .ant-select-selector {
    height: 48px !important;
    padding: 0 20px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 4px !important;
    color: #808889 !important;
    border-color: #D7D7D7 !important;
    box-shadow: none !important;
    min-width: 80px;

    &:not(.ant-select-disabled):hover {
      border: 1px solid rgba(248, 232, 0, 0.4) !important;
      box-shadow: 0 0 0 1px rgba(248, 232, 0, 0.4) !important;

      .ant-select-selection-item {
        color: #545454 !important;
      }
    }
  }

  .ant-select-arrow {
    color: #808889;
  }

  .ant-select-open {
    &:not(.ant-select-disabled) .ant-select-selector {
      &,
      &:hover {
        border: 1px solid #F8E800 !important;
        box-shadow: none !important;
      }

      .ant-select-selection-item {
        color: #1D1F21 !important;
      }
    }

    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }

  .ant-select-selection-placeholder {
    color: #808889;
    font-family: sans-serif !important;
    font-weight: 500;
  }

  &.ant-select-disabled {
    opacity: 0.5;
  }
}

.ErrorTreeSelectWrapper,
.ErrorSelectWrapper {
  .ErrorText {
    font-size: 10px;
    color: #e80012;
    margin-top: 2px;
  }

  .ant-select-selector {
    border-color: #e80012 !important;
  }
}

.ant-select-dropdown {
  border: 1px solid #D7D7D7;
  box-shadow: 0 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 4px !important;
  margin-top: -6px !important;

  .ant-select-item-option {
    padding: 8px 20px;
    font-weight: 500;

    &:not(.ant-select-item-option-disabled) {
      color: #545454;
    }

    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      color: #1D1F21;
    }

    &-selected {
      color: #1D1F21 !important;
      background-color: rgba(248,232,0,.2) !important;
      font-weight: 400 !important;
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: rgba(248,232,0,.2) !important;
  }
}
