.EmptyTableComponentWrapper {
  display: grid;
  place-items: center;
  padding: 15% 16px;
}

.EmptyTableIconTitle {
  padding-top: 14px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #1D1F21;
  max-width: 720px;
}

.EmptyTableIconDescription {
  max-width: 460px;
  padding-top: 14px;
  font-family: sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #1D1F21;
}

.EmptyTableButtonWrapper {
  padding-top: 35px;
}

.IconWrapperSvg {
  svg {
    width: 100% !important;
  }
}

.FilterEmptyTableIconTitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #545454;
}
