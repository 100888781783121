.EditModalDateCalendarComponentWrapper {
  margin-top: -24px;
}

.EditModalDateCalendarComponentWrapper .CalendarComponentWrapper
.ant-picker-calendar .ant-picker-panel {
  padding-left: 40px;
  padding-right: 40px;
}

.EditModalDateCalendarComponentWrapper .CalendarComponentWrapper
.ant-picker-calendar .ant-picker-panel .ant-picker-body
.ant-picker-content > tbody > tr > td > .ant-picker-cell-inner {
  height: calc((100vh - 455px) / 6) !important;
}

.EditModalDateCalendarComponentWrapper .CalendarComponentWrapper
.ant-picker-calendar .CalendarComponentHeaderEdit {
  margin-right: 40px;
}

.EditModalDateCalendarComponentWrapper .CalendarComponentWrapper
.ant-picker-calendar .CalendarComponentHeaderEdit .CalendarComponentHeaderDate {
  width: 190px;
  text-align: center;
}

.CalendarComponentHeaderEdit .CalendarComponentHeaderDate .CalendarComponentHeaderArrowLeft {
  margin-left: auto;
}

.CalendarComponentHeaderEdit {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 40px;
}


.CoursePopupTooltip .ant-tooltip-content {
  min-width: 370px;
}

.CoursePopupTooltipWrapper {
  display: flex;
  flex-direction: column;
  color: #E64646;
  font-family: sans-serif;
  line-height: 18px;
  padding: 18px 24px;
}

.CoursePopupTooltipTitle {
  font-weight: 500;
  font-size: 14px;
}

.CoursePopupTooltipText {
  font-size: 12px;
}
