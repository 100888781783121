.ErrorPageWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  margin-top: 5%;
}

.ErrorContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
