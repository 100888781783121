.ComponentWrapper {
  display: flex;
  flex-direction: column;
}

.HeaderWrapper {
  margin-left: auto;
  padding: 16px 0 16px 16px;
  display: flex;
}

.DeleteButtonWrapper {
  padding-right: 16px;
}

.TableWrapper {
  max-height: calc(100vh - 244px);
}

.TableWithoutPaginationWrapper {
  max-height: calc(100vh - 400px);
}

.TableWrapper .ant-table-thead > tr > th {
  font-weight: bold;
  color: #737373;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.TableWrapper .ant-table-tbody > tr {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
}

.TableWrapper .ant-table-tbody > tr:nth-of-type(2n),
.TableWrapper .ant-table-tbody > tr:nth-of-type(2n) .ant-table-cell-fix-left,
.TableWrapper .ant-table-tbody > tr:nth-of-type(2n) .ant-table-cell-fix-right {
  background-color: #fafafc;
}

.TableWrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fefde6;
}

.TableIconWrapper {
  display: flex;
  justify-content: space-between;
}

.TableIcon svg:hover {
  color: #737373;
}
