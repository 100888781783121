.Pagination {
  ul li a {
    color: #545454 !important;
    font-size: 14px;
    font-weight: 600 !important;
  }

  .ant-pagination {
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 12px;

    &-item {
      height: 40px;
      line-height: 40px;
    }
  }
}
.Pagination .ant-pagination-item,
.Pagination .ant-pagination-next,
.Pagination .ant-pagination-prev,
.Pagination .ant-pagination-jump-next {
  margin: 0;
  border-radius: 0;
}
.Pagination .ant-pagination-item {
  border: 0 !important;
  border-radius: 12px;
  min-width: 40px !important;
}
.Pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
  background-color: #f5f5f5 !important;
}
.Pagination .ant-pagination-item:focus,
.Pagination .ant-pagination-item-active {
  background: #ECECEC !important;

  a {
    color: #1D1F21 !important;
  }
}
.Pagination ul button {
  border-radius: 0 !important;
  border: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.Pagination ul li button {
  border-radius: 12px !important;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  height: 40px !important;
  min-width: 40px !important;
}

.Pagination .ant-pagination-jump-next:hover .ant-pagination-item-container,
.Pagination
  .ant-pagination-prev:not(.ant-pagination-disabled):focus
  .ant-pagination-item-link,
.Pagination
  .ant-pagination-next:not(.ant-pagination-disabled):focus
  .ant-pagination-item-link,
.Pagination
  .ant-pagination-prev:not(.ant-pagination-disabled):hover
  .ant-pagination-item-link,
.Pagination
  .ant-pagination-next:not(.ant-pagination-disabled):hover
  .ant-pagination-item-link {
  background-color: #f5f5f5 !important;
}
.Pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.Pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.Pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.Pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.Pagination .ant-pagination-item-link {
  color: #0d0d0d !important;
}
.Pagination .ant-pagination-disabled .ant-pagination-item-link {
  background-color: #f5f5f5 !important;
  color: #b3b3b3 !important;
}
.Pagination .ant-pagination-item-link .anticon {
  vertical-align: 0 !important;
}

.PaginationWrapper {
  margin: 24px auto;
}
