.ApproveModal {
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  z-index: 12;
}

.ApproveModalWrapper {
  width: 440px;
  top: calc(50% - 130px);
  left: calc(50% - 220px);
}

.SuccessApproveModal {
  height: 524px;
  top: calc(50% - 262px);
  left: calc(50% - 262px);
}

.ApproveModalClose {
  width: 100%;
  margin-left: 390px;
  padding-top: 16px;
  padding-right: 20px;
}

.ApproveModalClose:hover {
  cursor: pointer;
  opacity: 0.8;
}

.ApproveModalTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  padding-bottom: 16px;
}

.ApproveModalDescription {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  padding: 0 40px;
}

.ApproveModalButtons {
  display: grid;
  place-items: center;
  margin: 32px 0 40px 0;

  .ButtonDefaultWrapper {
    margin-right: 8px;
  }
}

.SuccessApproveModal {
  padding: 25px 102px 70px 102px;
}

.SuccessApproveImg {
  padding-bottom: 37px;
}

.SuccessApproveDescription {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}
