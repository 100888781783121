.InputWrapper > .ant-input,
.InputWrapper > .ant-input-number,
.InputWrapper > .ant-input-affix-wrapper,
.InputWrapper .ant-input-number-input {
  height: 48px;
  border-radius: 4px;

  &::placeholder {
    color: #808889 !important;
  }
}

.InputWrapper input {
  padding-left: 20px !important;
}

.InputWrapper > .ant-input:hover,
.InputWrapper > .ant-input-affix-wrapper:hover,
.InputWrapper > .ant-input-number:hover {
  border-color: #f8e800 !important;
}

.InputWrapper > .ant-input:focus,
.InputWrapper > .ant-input-focused,
.InputWrapper > .ant-input-affix-wrapper-focused,
.InputWrapper > .ant-input:focus,
.InputWrapper > .ant-input-number-focused,
textarea.ant-input:focus {
  box-shadow: 0 0 0 1px #f8e800 !important;
  border-color: #f8e800 !important;
}
.ErrorInputWrapper .ErrorText {
  font-size: 10px;
  color: #e80012;
  margin-top: 2px;
  margin-bottom: -16px;
}
.ErrorInputWrapper .ant-input {
  border-color: #e80012 !important;
}
.InputWrapper {
  .ant-input-disabled {
    opacity: 0.5 !important;
  }

  .ant-input-prefix {
    margin-right: 10px;
  }
}

.InputWrapper {
  .ant-input-number-handler-up {
    border-top-right-radius: 4px;
  }

  .ant-input-number-handler-down {
    border-bottom-right-radius: 4px;
  }

  .ant-input-number {
    &-handler {
      &-wrap {
        border-radius: 0 4px 4px 0;
      }

      &:hover {
        background: #f8e800;

        .anticon {
          color: #1D1F21;
        }
      }
    }
  }
}

