.ant-form-item-explain-error {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #FF3B30;
}

.ant-form-item-control-input {
  min-height: 32px !important;
}