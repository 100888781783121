.HistoryModalWrapper {
  position: absolute;
  z-index: 12;
  top: calc(50% - 326px);
  left: 50%;
  transform: translateX(-50%);
  width: 652px;
  background: #fff;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
}

.HistoryModalClose {
  margin-left: auto;
  width: 32px;
}

.HistoryModalClose:hover {
  cursor: pointer;
  opacity: 0.8;
}

.HistoryModalTitle {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #212529;
}

.HistoryModalTable {
  margin: 24px 16px 16px 16px;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
}

.HistoryModalTableRow, .HistoryModalTableRowTitle {
  border-bottom: 1px solid #E9ECEF;
}

.HistoryModalTableRowLast {
  border-bottom: none;
}

.HistoryModalTableRowItem {
  text-align: center;
  font-family: 'Lato', sans-serif !important;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.HistoryModalTableRowStatus {
  padding: 18px 21px;
  font-weight: 700;
  min-width: 120px;
  color: #212529;
}

.StatusPrefix {
  opacity: 0.5;
}

.HistoryModalTableRowName {
  padding: 18px 21px;
  width: 100%;
  color: #212529;
}

.HistoryModalTableRowDate {
  color: #495057;
  padding: 18px 21px;
  width: 300px;
}

.HistoryModalTableRowText {
  padding: 8px;
}

.HistoryModalTableRowLabel {
  font-weight: 700;
  color: #212529;
  padding: 8px 8px 0 8px;
  float: left;
}

.HistoryModalTableRowValue {
  padding-top: 8px;
  padding-right: 8px;
  padding-left: 8px;
}