.SchedulerCellWrapper {
  height: calc((100vh - 210px) / 6);
  //padding: 0 !important;
  align-items: center;
  border: 1px solid rgba(224, 224, 224, 1) !important;
  position: relative;
}

.SchedulerActiveCellWrapper:hover {
  cursor: pointer;
  background: rgba(248, 232, 0, 0.2) !important;
}

.DayScaleCellWrapper div {
  font-family: sans-serif !important;
  color: #808889 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.DayScaleCellWrapper div::first-letter {
  text-transform: uppercase;
}

.SchedulerCellDateWrapper {
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  border-radius: 50%;
  margin-top: 8px;
  font-family: sans-serif !important;
  padding: 4px 10px;
  align-items: center;
  vertical-align: center;
}

.AppointmentWrapper div {
  display: grid;
  place-items: center;
  padding: 2px;
}

.AvailableEntrollmentWrapper {
  span {
    margin: 0 auto;
    white-space: pre-wrap;
    text-align: center;
    font-family: sans-serif !important;

    font-weight: bold;
    font-size: 12px;
  }
}

.AppointmentTextWrapper {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  font-family: sans-serif !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
}

.MonthCustomNavigatorWrapper {
  display: flex;
  flex-direction: row;
}

.MonthCustomNavigatorTextWrapper {
  padding: 12px 8px 0 8px;
  font-family: sans-serif !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.MonthCustomNavigatorTextWrapper::first-letter {
  text-transform: uppercase;
}

.DragDropSourceWrapper,
.DragDropAppointmentComponentWrapper {
  border-radius: 8px;
  display: grid;
  place-items: center;
  padding: 2px;
}

.DragDropSourceWrapper {
  background: rgba(248, 232, 0, 0.2);
  height: 100%;
}

.MuiTable-root {
  border-spacing: 0 !important;
  border-collapse: separate !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 2px 0 rgba(0, 0, 0, 0.12) !important;
}
