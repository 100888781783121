.CreateComponentWrapper {
  margin: 0 5%;
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 120px);
  //min-width: 800px;
}

.CreateComponentContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.CreateComponentTitleWrapper {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  padding-top: 24px;
  padding-bottom: 22px;
  color: #1D1F21;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CreateComponentTitleButton {
  margin-top: -10px;
  padding-right: 12px;
}

.CreateComponentTitleButton:hover {
  cursor: pointer;
}

.CreateModal {
  background: #fff;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;
  min-height: calc(100vh - 195px);
}

.CreateModalTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #1D1F21;
  font-family: sans-serif;
}

.CreateComponentRightPanel {
  background: #fff;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  padding: 29px 0 29px 32px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
}

.RightPanelTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #1D1F21;
  padding-bottom: 16px;
  margin-bottom: 0;
}

.RightPanelBody {
  display: flex;
  flex-direction: column;
}

.RightPanelListItemWrapper {
  display: flex;
  flex-direction: column;
}

.RightPanelListLabelWrapper {
  display: flex;
  padding-top: 24px;
}

.RightPanelListLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1D1F21;
  margin-bottom: 0;
}

.RightPanelDisabled {
  color: #AAB4B5;
}

.RightPanelListLabelIsClickable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.RightPanelListDescription {
  font-size: 14px;
  line-height: 20px;
  color: #545454;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-right: 32px;
}

.RightPanelListDescriptionDisabled {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-right: 32px;
  color: #AAB4B5;
}

.RightPanelListRectangle {
  width: 100%;
  height: 1px;
  background: #E9E9E9;
  margin: 12px 0 0 0;
}

.CreateModalButtons {
  margin-top: auto;
  display: flex;
  flex-direction: row;
}

.RightPanelCreateWrapper {
  margin-left: -8px;
  margin-top: auto;
}

.RightPanelCreatButton {
  width: 252px;
}

.RightPanelCreatButton > span {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #1D1F21;
}

.RightPanelListCreateRectangle {
  margin-left: -32px;
  background: #EFF2F4;
  height: 1px;
  margin-bottom: 24px;
}

@media screen and (max-width: 1120px) {
  .CreateComponentWrapper {
    flex-direction: column;
  }

  .CreateComponentRightPanel {
    margin-top: 10px;
    border-radius: 20px;
    padding: 24px 0 24px 24px;
  }
}

@media screen and (min-width: 1121px) {
  .CreateComponentRightPanel {
    min-height: calc(100vh - 112px);
  }

 .CreateComponentWrapper {
   margin: 0 0 0 10%;
 }

  .CreateComponentContent {
    width: calc(100% - 400px);
  }

  .CreateModal {
    padding: 32px 40px;
  }

  .CreateComponentRightPanel {
    width: 300px;
    margin-left: 100px;
  }
}
