.HistoryWrapper {
  padding: 24px 5%;
}

.HistoryTitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  color: #1d1f21;
}

.HistoryEvents {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.HistoryEventsLeft {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 16px;
}

.HistoryEventsLeftItem {
  padding-top: 20px;
}

.HistoryEventsRight {
  padding-top: 20px;
}

.HistoryTableCourseNumber {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1D1F21;
}

.HistoryTableAuthor:hover {
  cursor: pointer;
  opacity: 0.8;
}

.HistoryTableAuthorName {
  padding-left: 8px;
}

.HistoryTableClubNumber {
  padding-left: 16px;
}

.HistoryTableMoreWrapper {
  width: 32px;
  font-size: 26px;
  color: #808889;
  padding: 0 0 14px 0;
  display: grid;
  place-items: center;
}

.HistoryTableMoreWrapper:hover {
  color: #545454;
  background: #F2F5F7;
  border-radius: 8px;
  cursor: pointer;
}

.HistoryTableCourseDescription {
  padding-top: 5px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
}

.ant-select-selection-item .TrainingTypeOptionsWrapper {
  height: 20px !important;
}

.ant-select-selection-item .TrainingTypeOptionsWrapper .HistoryTableCourseLabel {
  max-height: 40px !important;
}

.ant-select-selection-item > .TrainingTypeOptionsWrapper > .HistoryTableCourseDescription {
  padding-top: 0 !important;
}


.HistoryTableCourseLabel {
  color: #1D1F21;
  font-weight: 500;
}


.MorePopoverWrapper {
  display: flex;
  flex-direction: column;
  margin: -6px -10px;
}

.MorePopoverItem {
  display: flex;
  flex-direction: row;
  min-width: 140px;
  padding: 10px 14px;
  font-family: sans-serif;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  color: #1D1F21;
}

.MorePopoverItemIcon {
  margin-bottom: -4px;
}

.MorePopoverItemName {
  padding-left: 10px;
}

.MorePopoverItem:hover {
  background: #F2F5F7;
  border-radius: 4px;
  cursor: pointer;
}

.HistoryTableWrapper .ComponentWrapper
div div div div .ant-table,
.ant-table-container {
  border-radius: 10px 10px 0 0 !important;
}

.ant-table-thead .ant-table-cell {
  &:first-child {
    border-radius: 10px 0 0 0 !important;
  }

  &:last-child {
    border-radius: 0 10px 0 0 !important;
  }
}

.HistoryTableWrapper .ComponentWrapper
div div div div div .ant-table-container
.ant-table-header table .ant-table-thead tr > .ant-table-cell {
  font-family: sans-serif;
  background: #fff !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  color: #808889 !important;
  padding: 4px 16px !important;
  text-transform: uppercase;
}

.ant-table-cell-scrollbar {
  box-shadow: none !important;
  width: 25px;
}

.HistoryTableWrapper .ComponentWrapper
div div div div div .ant-table-container  .ant-table-tbody {
  .ant-table-cell {
    color: #545454;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  > tr:not(.ant-table-measure-row) {
    > .ant-table-cell {
      padding: 24px 16px !important;

      &:first-child {
        padding-left: 32px !important;
      }
    }
  }
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  color: #aab4b5 !important;

  &.active {
    color: #545454 !important;
  }
}

@media screen and (min-width: 992px) {
 .HistoryWrapper {
   padding: 24px 10%;
 }

  .HistoryEventsRight {
    margin-left: auto;
  }
}

.ant-popover {
  z-index: 10 !important;
}