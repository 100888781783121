.EditEmployeeSearchWrapper {
  width: 224px;
  margin-left: 16px;
  margin-top: 15px;
}

.EditEmployeeTableWrapper {
  padding-top: 24px;
}

@media screen and (min-width: 992px) {
  .EditEmployeeSearchWrapper {
    width: 224px;
    margin-left: auto;
    margin-top: -40px;
    margin-right: 40px;
  }
}
