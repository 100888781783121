.BaseLayoutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.BaseLayoutHeader {
  min-height: 72px;
  flex-shrink: 0;
  background-color: #2b2d2d;
  padding: 10px 5%;
  display: flex;  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.BaseLayoutHeaderLogo {
  cursor: pointer;
}

.BaseLayoutHeaderMenu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.BaseLayoutHeaderLogOut {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.BaseLayoutHeaderLinks {
  padding-right: 16px;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
}

.BaseLayoutHeaderLinks a {
  color: #fff !important;
}

.BaseLayoutHeaderLinkActive a {
  color: #f8e800 !important;
}

.BaseLayoutBody {
  width: 100%;
  flex: 1;
  background-color: #F2F5F7;
  //height: calc(100vh - 112px);
}

.BaseLayoutFooter {
  padding: 0 5%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #F2F5F7;
}

.BaseLayoutFooterLeft {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #808889;
  vertical-align: center;
  padding-top: 12px;
}

.BaseLayoutFooterRight {
  display: flex;
  align-items: center;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: #1D1F21;
  padding: 8px 0 0 0;
}

.BaseLayoutFooter-link {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.FooterPhone-icon {
  display: inline-flex;
  margin-right: 9px;
}

.BaseLayoutSelectRole {
  width: 200px;
}

.BaseLayoutHeaderLogOutIcon {
  padding-left: 16px;
}

.AdminSelectsWrapper {
  display: flex;
  flex-direction: row;
}

.AdminSelectsHallWrapper {
  padding-right: 8px;
}

@media screen and (max-width: 992px) {
  .BaseLayoutHeaderLogo {
    margin: 10px 20px 0 0;
  }

  .BaseLayoutHeaderLogOut {
    margin: 10px 0;
  }

  .BaseLayoutFooter {
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 20px;
  }
}

@media screen and (min-width: 992px) {
  .BaseLayoutHeader {
    padding: 0 24px 0 10%;
  }

  .BaseLayoutHeaderLogo {
    padding-right: 48px;
    margin-left: 60px;
  }

  .BaseLayoutHeaderLogOut {
    margin-left: auto;
  }

  .BaseLayoutFooter {
    padding: 0 0 0 10%;
  }

  .BaseLayoutFooterRight {
    margin-left: auto;
    padding: 8px calc(10% - 138px) 0 20px;
  }
}
