.ModalOverflow {
  width: 100%;
  height: 100%;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
}

.CancelModalWrapper {
  position: absolute;
  z-index: 12;
  top: calc(50% - 146px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 450px;
  background: #fff;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 4px;
}

.CancelModalCloseWrapper {
  width: 39px;
  padding-top: 20px;
  margin-right: 20px;
  height: 39px;
  margin-left: auto;
  cursor: pointer;
}

.CancelModalTitle {
  text-align: center;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #1D1F21;
  padding-top: 12px;
}

.CancelModalText {
  padding-top: 16px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #1D1F21;
  padding-left: 60px;
  padding-right: 60px;
}

.CancelModalCommentWrapper {
  padding: 24px 60px 0 60px;

}

.CancelModalButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

