.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr .ant-picker-cell-selected
.ant-picker-cell-inner {
  background-color: rgba(255, 255, 255, 0) !important;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr .ant-picker-cell-selected
.ant-picker-cell-inner .ant-picker-calendar-date-value {
  color: #1D1F21 !important;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content thead tr > th {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14.0287px;
  line-height: 100%;
  color: #808889;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr > td{
  background: #FFF !important;
  border: 0.8px solid #E9E9E9 !important;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr > td .ActiveCalendarCell{
  background: rgba(248, 232, 0, 0.2) !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0px;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr > .ant-picker-cell-disabled {
  background: #FBFBFB !important;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr > td .ant-picker-cell-inner:hover {
  //background-color: #fff !important;
  cursor: auto;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr > td .ant-picker-cell-inner {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content tbody > tr > td .ant-picker-cell-inner
.ant-picker-calendar-date-value {
  padding-right: 7px;
  padding-top: 7px;
  margin-bottom: -7px;
  margin-left: -7px;
  font-size: 14.0287px;
  line-height: 14px;
}

.CalendarComponentWrapper .ant-picker-calendar
.ant-picker-panel .ant-picker-body
.ant-picker-content {
  border-spacing: 0 !important; // fix bug border FF
  border-collapse: separate !important; // fix bug border FF
}
