@import "./CreateModal/styles";
@import "./RouteComponents/styles";

.InfoWrapper {
  flex: 1 1 0;
  font-size: 16px;
  padding: 24px 98px;

  &-header {
    margin: 0 0 22px 0;
  }

  &-list {
    margin-top: 22px;
  }

  .highlight {
    font-family: sans-serif;
    display: inline-block;
    padding: 2px 20px;
    background: rgba(248, 232, 0, 0.2);
    font-weight: 700;
    margin-bottom: 5px;
  }

  .withIcon {
    display: flex;

    svg {
      margin-right: 10px;
      margin-top: 3px;
      flex-shrink: 0;
    }
  }
}

.InfoWrapperHeader {
  margin-bottom: 20px;
}

.InfoItem {
  margin-bottom: 16px;
  border-radius: 12px;
  background: @white;
  box-shadow: 0 0 0 1px #D7D7D7;

  &-open {
    p:last-child {
      margin-bottom: 0;
    }

    .InfoItem-header {
      box-shadow: 0 1px 0 #D7D7D7;
    }

    .InfoItem-icon {
      transform: rotate(180deg);
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 35px 23px 40px;
    font-family: sans-serif;
    position: relative;

    h2 {
      margin-bottom: 3px;
      font-weight: 700;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-description {
    font-size: 16px;
    color: #808889;
  }

  &-body {
    padding: 25px 20px 20px 20px;
    line-height: 2;

    > div {
      margin-bottom: 15px;
    }

    ul {
      padding-left: 18px;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
  }

  &-icon {
    height: 24px;
  }

  &-hide {
    position: absolute;
    bottom: 0;
    right: 24%;
    height: 112px;
    overflow: hidden;
  }

  &-bg {
    position: absolute;
    bottom: 0;
    right: 20%;
    font-size: 60px;
    font-weight: 700;
    line-height: 100px;
    overflow: hidden;
    height: 100px;
    color: rgba(170,180,181,.15);

    &:first-letter {
      font-size: 140px;
    }
  }
}

.InfoItemArchiveLabel {
  background: #ECECEC;
  border-radius: 21px;
  padding: 8px 18px;
  height: fit-content;
  margin-left: 24px;

  span {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-left: 8px;
    margin-top: 2px;
  }
}

.ListLeftMargin {
  margin-left: 32px;
}

@media screen and (min-width: 768px) {
  .InfoWrapper {
    padding: 24px 98px;
  }

  .InfoItem {
    &-body {
      padding: 37px 40px 20px 40px;
    }
  }
}

.CreateButtonTitle {
  padding-top: 4px;

  span {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: #000000;
  }
}

.CreateButtonWrapper {
  margin-left: auto;
}

.CreateButtonLabel {
  svg {
    margin-top: 6px;
  }

  .CreateButtonLabelText {
    padding-left: 12px;
    color: #000000;
  }
}
