.HowToGetWrapper {
  flex: 1 1 0;
  font-size: 16px;
  padding: 24px 5%;
  margin: 0 auto;

  &-header {
    margin: 0 0 22px 0;
  }

  &-body {
    padding: 24px;
    border-radius: 12px;
    margin-top: 22px;background: #FFFFFF;
    border: 1px solid #D7D7D7;
    box-shadow: 0 4px 6px rgba(62, 73, 84, 0.04);
  }

  &-icon {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;

    svg {
      margin: 0 8px 0 0;
    }

    b {
      font-size: 20px;
      font-weight: 700;
      margin-right: 12px;
    }
  }

  &-map {
    margin: 15px 0 30px;
  }

  &-row {
    margin-bottom: 30px;
    display: flex;

    &-desc {
      flex-direction: column;

      .InfoWrapper-cell {
        display: flex;
        flex: 1;

        &:first-child {
          position: relative;
          padding: 24px 32px 10px;
          background-color: rgba(0, 122, 255, 0.1);
          border: 1px solid #007AFF;
          border-radius: 8px;
          margin-right: 7px;
          flex-direction: column;
          margin-bottom: 20px;

          &:before {
            content: "";
            width: 24px;
            height: 24px;
            background-image: url("/Components/icons/info-icon.svg");
            position: absolute;
            right: 8px;
            top: 8px;
          }
        }

        &:last-child {
          flex-direction: column;
        }
      }
    }
  }

  &-img {
    margin-bottom: 10px;

    img {
      height: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .HowToGetWrapper {
    &-body {
      padding: 40px;
    }

    .InfoWrapper-row-desc {
      .InfoWrapper-cell:last-child {
        flex-direction: row;
      }

      .InfoWrapper-img {
        &:first-child {
          margin-right: 7px;
        }

        &:last-child {
          margin-left: 7px;
        }

        img {
          height: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .HowToGetWrapper {
    padding: 24px 10%;
  }

  .InfoWrapper-row-desc {
    flex-direction: row;

    .InfoWrapper-img {
      margin: 0 0 0 15px;
      min-height: 208px;
    }

    .InfoWrapper-cell {
      &:first-child {
        margin-bottom: 0;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 1520px) {
  .HowToGetWrapper {
    padding: 24px 15%;
  }
}
