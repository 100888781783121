.EmployeeHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
}

.EmployeeSearchWrapper {
  padding-right: 40px;
  width: 224px;
}

.EmployeeTableWrapper {
  margin-right: -40px;
  margin-left: -40px;

  .ant-table {
    z-index: 2;
  }
}

.EmployeeTableCheckColumnWrapper {
  display: flex;
  flex-direction: row;
}

.EmployeeTableCheckColumnText {
  padding-left: 4px;
}

.EmployeeTablePaginationWrapper {
  margin-left: auto;
  margin-bottom: -62px;
  padding-top: 29px;
  padding-right: 40px;
}

.EmployeeTableWrapper .ComponentWrapper
div div div div div .ant-table-container >
.ant-table-header table thead tr > .ant-table-cell{
  background-color: #FBFBFB;
  border-right: 0 !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #1D1F21;
}

.EmployeeTableWrapper .ComponentWrapper
div div div div div .ant-table-container >
.ant-table-body table tbody tr > .ant-table-cell {
  border-right: 0 !important;
  font-size: 14px;
  line-height: 17px;
  color: #1D1F21;
}

.EmployeeTableTextDisabled {
  color: #1D1F21 !important;
  opacity: 0.5 !important;
}

@media screen and (max-width: 1120px) {
  .EmployeeTableWrapper {
    margin-right: -16px;
    margin-left: -16px;
  }

  .EmployeeHeaderWrapper {
    flex-direction: column;
  }

  .CreateModalTitle {
    margin-bottom: 15px;
  }

  .EmployeeSearchWrapper {
    padding-right: 0;
    width: 100%;
  }
}

.EmployeeTableComponentWrapper {
  display: grid;
  place-items: center;
  padding-top: calc(50vh - 485px);
}
