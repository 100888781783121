.CheckboxWrapper {
  display: inline-flex;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background: #F5F6F8;
    border-color: #AAB4B5;

    &:after {
      width: 7px;
      height: 11px;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #F8E800;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #F8E800;
    border-color: #F8E800;
  }

  .ant-checkbox-checked::after {
    border-color: #F8E800;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after,
  .ant-checkbox-checked:hover .ant-checkbox-inner:after {
    border-right-color: #1D1F21;
    border-bottom-color: #1D1F21;
  }

  .ant-checkbox-wrapper-disabled,
  .ant-checkbox-wrapper-disabled + .CheckboxText {
    opacity: 0.5;
  }
}

.CheckboxText {
  font-size: 15px;
}


