.StatusComponentWrapper {
  border-radius: 6px;
  display: inline-block;
  width: content-box;
}

.StatusComponentEditableWrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}

.StatusComponentLabel {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 7px 10px;
}
