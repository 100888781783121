.PanelEmployeeListWrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 580px);
  overflow-y: auto;
}

.PanelEmployeeListItem {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PanelEmployeeListItemName {
  font-family: sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  padding: 11px;
  max-width: 244px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.PanelEmployeeListItemDelete {
  display: none;
  padding: 12px 8px;
}

.PanelEmployeeListItemDelete:hover {
  cursor: pointer;
}

.PanelEmployeeListItem:hover {
  background: #F2F5F7;
  border-radius: 6px;
}

.PanelEmployeeListItem:hover > .PanelEmployeeListItemDelete {
  display: inherit;
}
