.PhoneInputComponentWrapper {
  max-width: 200px;
}

.PhoneInputComponentMaskRectangle {
  width: 100%;
  height: 1px;
  background-color: #F8E800;
}

.PhoneInputComponentSpanWrapper {
  display: flex;
  flex-direction: row;
}

.PhoneInputComponentSpan:hover {
  cursor: pointer;

  svg {
    fill: #000 !important;
  }
}

.PhoneInputIconWrapper:hover {
  cursor: pointer;
  opacity: 0.6;
}

.PhoneInputIconDisabled {
  cursor: default !important;
  opacity: 0.4;
}

.PhoneInputIconsWrapper {
  min-width: 24px;
}

.EmployeePhoneInput {
  display: flex;
  input {
    height: 24px !important;
    padding-left: 0 !important;
  }
}