.HistoryEventsLeftItemButtonIconWrapper {
  padding-top: 4px;
  padding-right: 6px;
}

.ReportUserStatus {
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
}
