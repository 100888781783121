.CheckModalWrapper {
  position: absolute;
  z-index: 12;
  top: calc(50% - 250px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 794px;
  background: #fff;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 20px 20px 40px;
}

.CheckModalHeaderClose {
  margin-left: auto;
}

.CheckModalHeaderClose:hover {
  cursor: pointer;
  opacity: 0.8;
}

.CheckModalHeaderTitle {
  font-family: 'Gilroy', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  padding: 14px 20px 8px 0;
}

.CheckModalDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 24px;
  width: 690px;
}

.CheckModalTableTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 12px;
}

.CheckModalTableContent {
  padding: 14px 24px 16px 24px;
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.CheckModalTableContentLabel, .CheckModalTableContentLabel span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #545454;
}

.CheckModalTableContentLabel svg {
  margin-top: 2px;
  margin-left: 4px;
}

.CheckModalTableContentValue {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  min-width: 60px;
}

.CheckModalTableContentValueName {
  min-width: 250px !important;
}


.CheckModalTableContentValueSnils {
  width: 160px !important;
}

.Rectangle {
  height: 1px;
  background: #ECECEC;
  width: 100%;
}

.CheckModalButtons {
  padding-top: 24px;
  padding-bottom: 12px;
  margin: 0 auto;
  display: grid;
  place-items: center;

  div .ButtonCancelWrapper {
    padding-right: 12px;
  }
}

.CheckModalTableContentValueSnils {
  input {
    padding-left: 0 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}