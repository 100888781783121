.EditComponentModalWrapper {
  position: absolute;
  top: 10px;
  bottom: 67px;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 139px);
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  z-index: 12;
}

.EditComponentModalContentWrapper {
  display: flex;
  flex-direction: column;
}

.EditComponentModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

.EditComponentModalHeaderTitle {
  padding-top: 40px;
  padding-left: 16px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #1D1F21;
}

.EditComponentModalHeaderClose {
  padding: 24px;
  cursor: pointer;
}

.EditComponentTabsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 320px;
  height: 40px;
  background: #F2F5F7;
  border-radius: 4px;
  margin-left: 16px;
  z-index: 9;
}

.EditComponentTitleWithoutDate {
  padding-left: 16px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1D1F21;
}

.EditComponentTabsItem {
  text-align: center;
  align-items: center;
  vertical-align: center;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1D1F21;
  width: 156px;
  height: 32px;
  margin: 4px;
  padding-top: 6px;
  cursor: pointer;
}

.EditComponentTabsActiveItem {
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: auto !important;
}

.EditComponentTabsDisabledItem {
  cursor: auto !important;
  color: #808889;
}

.EditComponentModalFooterWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
}

.EditComponentFooterButtonsWrapper {
  margin-top: 24px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: row;
}

.EditComponentModalFooterEmployeeCounterWrapper {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  min-width: 200px;
}

.EmployeeCounterDescription {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #1D1F21;
  margin-right: 10px;
  margin-top: 3px;
}

.EmployeeCounterItem {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #1D1F21;
}

.EmployeeCounterItemRed {
  color: #FF6E6E !important;
}

@media screen and (max-width: 991px) {
  .EditComponentFooterButtonsWrapper {
    margin-top: 20px !important;
    margin-left: 16px;
  }

  .EditComponentModalFooterWrapper {
    flex-direction: column;
  }

  .EditComponentModalWrapper {
    height: calc(100vh - 50px);
  }
}

@media screen and (min-width: 992px) {
  .EditComponentModalWrapper {
    position: absolute;
    top: 72px;
    bottom: 67px;
    left: 20%;
    right: 20%;
    width: calc(100vw - 40%);
    border: 1px solid #D7D7D7;
  }

  .EditComponentModalHeaderTitle,
  .EditComponentTitleWithoutDate {
    padding-left: 40px;
  }

  .EditComponentModalFooterEmployeeCounterWrapper,
  .EditComponentTabsWrapper {
    margin-left: 40px;
  }

  .EditComponentModalFooterWrapper {
    margin-right: 40px;
  }
}
