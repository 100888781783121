@black-0: rgb(0,0,0);
@black-1: rgb(29,31,33);
@black-2: rgb(82,84,97);
@black-3: #757681;
@white: #fff;
@grey-bg: #F5F6F8;
@grey-2: #DEDFE1;
@grey-3: #EEEEEF;
@action-info: #2D8AF1;
@action-danger: #FF7454;
@action-warning: #FFA132;
@light-primary: rgb(222,245,235);
@light-info: #E0EDFD;
@light-warning: #FFF1E0;
@light-danger: #FFEAE5;
@main: #F8E800;
@main-dark: #F5D400;
@main-light: #B9E9D5;
@main-lighter: @light-primary;

@primary: @main;
@secondary: @grey-3;
@border-radius: 2px;
@height-base: 56px;
@height-lg: 64px;
@height-sm: 48px;

body {
  margin: 0 !important;
  font-family: Lato, sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  min-width: 375px !important;
  color: @black-1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  h1, h2, h3, h4, h5, h6 {
    font-family: sans-serif;
  }

  h1,
  .Title-h1 {

    font-size: 30px;
    line-height: 1.25;
    font-weight: 700;
    margin-bottom: 0;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  img {
    display: block;
    max-width: 100%;
  }

  input {
    font-family: Lato, sans-serif !important;
    font-weight: 500;

    &::placeholder {
     color: #808889 !important;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(@white);
  border-radius: 2px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: rgba(@white);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(@black-3, 0.3);
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background-color: rgba(@white);
}

iframe {
  display: none !important;
}