@import "./Days/styles";
@import "../Approve/styles";

.CreateModalHeader {
  margin-bottom: 24px;

  div svg {
    margin-top: 10px;
    margin-right: 20px;
    cursor: pointer;
  }

  .CreateModalTitle {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: #000000;
  }
}

.CreateModalFormWrapper {
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 20px;
  padding: 32px 40px;
}

.CreateModalFormItemWrapper {
  padding-bottom: 24px;
  width: calc(50% - 24px);
  margin-right: 24px !important;
}

.CreateModalFormItemFormatDescription {
  width: calc(100% - 24px);
}

.CreateModalFormItemTextAreaWrapper {
  padding-bottom: 24px;
}

.CreateCourseFieldLabel {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 13px;
}

.CreateCourseSelectField {
  width: calc(50vw - 162px) !important;
  margin-right: 40px !important;
}

.CreateModalFormTimeRow {
  .CreateModalFormItemWrapper {
    margin-right: 40px;
  }
}

.CreateModalSubmitButton {
  width: 252px;
}

.LessonDurationSuffix {
  position: absolute;
  left: 30px;
  width: 200px;
}

.LessonDurationSuffixHide {
  display: none;
}