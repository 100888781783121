.MoreModalWrapper {
  position: absolute;
  right: 0;
  top: 72px;
  height: calc(100vh - 72px);
  background: #fff;
  width: 300px;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 999 !important;
  display: flex;
  flex-direction: column;
  padding: 35px 0 0 25px;
}

.MoreModalOverflow {
  width: 100%;
  height: calc(100vh - 72px);
  z-index: 11;
  position: absolute;
  top: 72px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.MoreModalHeader {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.MoreModalTitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #1D1F21;
}

.MoreModalStatus {
  padding-left: 14px;
  margin-top: -4px;
}

.MoreModalContentTitle {
  padding-top: 24px;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1D1F21;
  padding-left: 10px;
  padding-right: 16px;
}

.MoreModalContentData {
  padding: 12px 16px 12px 10px;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #545454;
}

.MoreModalContentEmployeeWrapper {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  padding-left: 10px;
  padding-right: 16px;
}

.MoreModalContentEmployeeItem {
  padding-top: 12px;
}

.MoreModalContentRectangle {
  height: 1px;
  background: #E9E9E9;
}

.MoreModalFooter {
  margin-top: auto;
}

.MoreModalFooterButtonsWrapper {
  display: flex;
  flex-direction: row;
  padding-top: 18px;
  padding-bottom: 24px;
}

.MoreModalFooterCancelWrapper {
  padding-top: 7px;
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #EB5757;
  padding-right: 30px;
}

.MoreModalFooterCancelWrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}

.MoreModalFooterCancelText {
  padding-left: 6px;
}
