.LoginWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #2b2d2d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginWrapperIcon {
  padding-top: 20px;
  padding-bottom: 20px;
}

.LoginFormWrapper {
  margin-top: calc(50vh - 271px);
  border-radius: 16px;
  box-shadow: 0 25px 50px 0 rgba(75, 75, 77, 0.15);
  border: solid 1px #e6e6e6;
  background-color: #fff;
  padding: 46px;
  width: 100%;
  max-width: 504px;
}

.LoginFormLabel {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #0d0d0d;
  padding-bottom: 38px;
}

.LoginFormFieldWrapper {
  display: grid;
  place-items: center;
}

.LoginFormField {
  width: 240px;
  padding-top: 8px;
}

.ErrorMessage {
  display: grid;
  place-items: center;
  color: #ff0025;
  padding: 5px 0;
}

.LoginFormButtons {
  display: grid;
  place-items: center;
}

.LoginFormEnter {
  width: 240px;
  height: 48px !important;
  color: #0d0d0d !important;
}

.LoginFormEnter:hover {
  background-color: #ebdb00 !important;
  border-color: #ebdb00 !important;
}

@media screen and (min-width: 992px) {
  .LoginWrapperIcon {
    padding-top: 44px;
  }
}
