.OpenCategoryButtonsWrapper {
  gap: 16px;
  margin-top: 16px;
}

.OpenCategoryButton span {
  margin-top: 1px;
}

.OpenCategoryButtonEdit span {
  margin-top: 3px;
}

.OpenCategoryButtonUnzip span {
  margin-top: 5px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #000000;
}

.OpenCategoryButtonsIcon {
  margin-top: 3px;
  margin-right: 10px;
}

.InfoListItem {
  min-width: 1020px;
}

.InfoWrapperTitleTabs {
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  padding: 4px;
  margin-left: 24px;
}

.InfoItemTitle span {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;

  min-width: 300px;
  max-width: calc(100vw - 1200px);
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.InfoWrapperTitleTabsItem {
  width: 156px;
  padding: 8px 0;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #545454;
}

.InfoWrapperTitleTabsItemActive {
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: black !important;
}

.InfoWrapperTitleTabsItemNotActive:hover {
  color: black !important;
  cursor: pointer;
}

.OpenCategoryVisibleButtons .ButtonPrimaryWrapper .ButtonPrimary {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.InfoItemCalendarLock {
  margin-left: 8px;
  width: 10px;
  height: 10px;

  svg path {
    color: #808889;
  }
}