.SuccessEditClose {
  width: 54px;
  margin-top: 24px;
  margin-left: auto;

}

.SuccessEditContentWrapper {
  display: grid;
  place-items: center;
  height: calc(100vh - 461px);
}

.SuccessEditTitle {
  padding-top: 48px;
  padding-bottom: 32px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #1D1F21;
}

.SuccessEditTimer {
  padding-top: 97px;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #808889;
}
