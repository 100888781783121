.CreateGraphModalWrapper, .CreateGraphModalSuccessWrapper {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 12;
  width: 100%;
  background: #fff;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 4px;
}

.CreateGraphModalCloseWrapper {
  width: 39px;
  padding-top: 20px;
  margin-right: 20px;
  height: 39px;
  margin-left: auto;
  cursor: pointer;
}

.CreateGraphModalContent {
  padding: 24px 16px 16px 16px;
}

.CreateGraphModalContentFieldDescription {
  font-family: sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 8px;
}

.CreateGraphModalContentField {
  padding-bottom: 16px;
}

.CreateGraphModalContentFieldRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.CreateGraphModalButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.CreateGraphModalDeleteWrapper {
  margin-left: 12px;
  height: 48px;
  padding: 12px 8px 0 8px;
}

.CreateGraphModalDeleteWrapper span {
  margin-bottom: -4px;
}

.CreateGraphModalDeleteWrapper:hover {
  cursor: pointer;
  background: rgba(84, 84, 84, 0.1);
  border-radius: 6px;
}

.ant-picker {
  border-radius: 4px !important;

  &:hover {
    border-color: #f8e800 !important;
  }

  &-focused {
    box-shadow: 0 0 0 1px #f8e800 !important;
    border-color: #f8e800 !important;
  }

  .ant-picker-suffix {
    color: #808889;
  }

  &-dropdown {
    .ant-btn-primary {
      background: #f8e800;
      border-color: #f8e800;
      text-shadow: none;
      box-shadow: none;
      color: #1D1F21;
      height: 28px;
      padding-top: 3px;
      padding-bottom: 3px;

      &:not([disabled]) {
        &:hover,
        &:focus,
        &:active {
          border-color: #F5D400;
          background: #F5D400;
          color: #1D1F21;
        }
      }
    }

    .ant-picker {
      &-now-btn {
        color: #808889;

        &:hover {
          color: #1D1F21;
        }
      }

      &-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        color: #1D1F21;
      }

      &-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
          background-color: rgba(248, 232, 0, 0.2) !important;
        }
      }

      &-ranges {
        display: flex;
        padding: 6px 12px 4px;
      }
    }
  }
}

.CreateGraphModalEmployees {
  padding-bottom: 24px;
}

.CreateGraphModalEmployeesTitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1D1F21;
  margin-top: 6px;
}

.CreateGraphModalEmployeesWrapper {
  max-height: 110px;
  overflow: auto;
}

.FlexRow {
  display: flex;
  flex-direction: row;
}

.CreateGraphModalEmployeesHall {
  width: 140px;
}

.CreateGraphModalEmployeesHall span {
  width: 100px;
  margin-top: 7px;
}

.CreateGraphModalEmployeesHall .ant-form-item .ant-form-item-control
  .ant-form-item-explain {
  position: absolute;
  top: 30px;
  left: -57px;
}

.CreateGraphModalEmployeesPhone .ant-form-item .ant-form-item-control
.ant-form-item-explain {
  position: absolute;
  top: 30px;
  left: -18px;
}

.CreateGraphModalEmployeesPhone span {
  margin-top: 7px;
  margin-right: 2px;
}

.CreateGraphModalEmployeesInput {
  padding: 4px 0 0 0  !important;
}

.CreateGraphModalEmployeesName {
  width: 260px;
}

.CreateGraphModalEmployeesPhone {
  width: 160px;
}

.CreateGraphModalEmployeesPhone, .CreateGraphModalEmployeesName, .CreateGraphModalEmployeesSnils {
  padding-left: 16px;
}

@media screen and (min-width: 920px) {
  .CreateGraphModalWrapper {
    top: calc(50% - 370px);
    left: calc(50% - 397px);
    width: 794px;
  }
}

@media screen and (min-width: 992px) {
  .CreateGraphModalContent {
    padding: 32px 32px 16px 32px;
  }
}

.CreateGraphAddEmployees {
  margin-left: 12px;
  padding: 6px 14px;
  background: rgba(248, 232, 0, 0.2);
  border: 1px solid #F8E800;
  border-radius: 4px;
}

.CreateGraphAddEmployees:hover {
  cursor: pointer;
  opacity: 0.8;
}

.CreateGraphAddEmployees svg {
  margin-top: 3px;
}

.CreateGraphAddEmployeesTitle {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-left: 8px;
}

.CreateGraphModalSuccessWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 524px;
  padding: 48px 102px 70px 102px;
  border-radius: 4px;
  display: grid;
  place-items: center;
}

.CreateGraphModalSuccessTitle {
  padding-top: 56px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}