.SuccessCreateLayoutWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  display: grid;
  place-items: center;
}

.SuccessCreateWrapper {
  width: 100%;
  max-width: 600px;
  height: 600px;
  display: grid;
  place-items: center;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 8px;
}

.SuccessCreateHeader {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #1D1F21;
  padding-top: 30px;
}

.SuccessCreateDescription {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #545454;
}

.SuccessCreateRedirectButton {
  width: 168px;
  margin-top: 32px;
  margin-bottom: 32px;
}
